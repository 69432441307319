import React, { Fragment } from 'react';

const NotFound = () => {
    return (
        <Fragment>
        <p><b>404</b>. That’s an error. </p>
        <p>The requested URL /asdasd was not found on this server. That’s all we know.</p>
        </Fragment>
    );
};

export default NotFound;